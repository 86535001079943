import { createElement, useCallback, useEffect, useMemo, useState } from "react";
import {
	BreadcrumbItem,
	Breadcrumbs, ConfirmDialog,
	DataTable,
	DataTableCellProps,
	DataTableColumn,
	DataTableColumnSettings,
	DataTableHeader, EditForm,
	Filter, PlainDateTime, PlainDateTimePicker, RadioGroup, Selector,
	useDataTableColumnStorage,
	useDataTableStorage,
	useFlag,
	useSpringPage,
	useSpringPageable, useStorage,
} from "@variocube/app-ui";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { itemApi, ItemFilterTimeframeType, ItemListFilter, V2Item } from "../../data/items";
import { tenantUserStore } from "../../store/TenantUserStore";
import {
	Box,
	Chip,
	Dialog, DialogContent,
	DialogTitle,
	Grid, LinearProgress,
	Link,
	MenuItem,
	Stack,
	Typography
} from "@mui/material";
import { columnSettingsLabels, filterLabels, formLabels, useLocalization } from "../../i18n";
import { PushButtonWithMenu } from "../../components/buttons/PushButtonWithMenu";
import { RentalAppContainer } from "../../RentalAppContainer";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Cube } from "../../data/types";
import { ItemStateDisplay } from "./ItemStateDisplay";
import { cubeApi } from "../../data/cubes";
import { CategorySelection } from "../../components/category-selection";
import { ModelSelection } from "../../components/model-selection";
import { useDateTimeFormat } from "@variocube/app-ui/esm/formats/useDateTimeFormat";
import { categoryApi } from "../../data/categories";
import { modelApi } from "../../data/models";
import { convertInstantString, convertPlainDateTime } from "../../tools";
import { AddFab } from "../../components/AddFab";

export function ItemList() {
	const { t, language } = useLocalization();
	const formatter = useDateTimeFormat();
	const centerId = tenantUserStore.getTenantId();
	const navigate = useNavigate();

	const [filter, setFilter] = useStorage<ItemListFilter>("ItemListFilter", {})

	const [filterDialog, setFilterDialog, clearFilterDialog] = useFlag(false);
	const [accessCodeDialog, setAccessCodeDialog, clearAccessCodeDialog] = useFlag(false);

	const { result: cubes = [], execute: reloadCubes } = useAsync(async () => {
		const cubeIds = new Set(rows.map(i => i.stored?.cubeId).filter(id => !!id) as string[]);
		return Promise.all(Array.from(cubeIds).map(id => cubeApi.get(centerId, id)))
	}, []);

	const available: DataTableColumn<V2Item>[] = useMemo(() => [
		{ field: 'name', sortable: true, label: t('common.name'), default: true, component: ItemName },
		{ field: 'foreignId', sortable: true, label: t('items.foreignId'), default: true, component: ItemForeignId },
		{ field: 'category', sortable: true, label: t('categories.singular'), default: true, component: ItemCategory },
		{ field: 'model', sortable: true, label: t('models.singular'), default: true, component: ItemModel },
		{ field: 'stored.cubeId', label: `${t("cubes.singular")} / ${t("boxes.singular")}`, default: true, component: cell => ItemStorage({ cell, cubes }) },
		{ field: 'adminCode', sortable: true, label: t("items.codes.adminCode"), component: ({ row }) => <span>{row.adminCode ?? '-'}</span> },
		{ field: 'openCode', sortable: true, label: t('items.codes.openCode'), component: ({ row }) => <span>{row.openCode ?? '-'}</span> },
		{ field: 'disabled', sortable: true, label: t('common.state'), default: true, component: ItemState },
	], [t, cubes]);

	const { columns, setColumns } = useDataTableColumnStorage("ItemListTableColumns", available);
	const storage = useDataTableStorage("ItemList", { pageSize: 25 });
	const pageable = useSpringPageable(storage);

	const { result, loading, error, execute: reload } = useAsync(() => itemApi.list(centerId, pageable, filter), [pageable, filter]);
	const { rows, page } = useSpringPage(result);

	useEffect(() => {
		reloadCubes()
			.catch(console.error);
	}, [page]);

	const hasTimeframeFilter = useMemo(() => {
		if ((!!filter.from || !!filter.until) && !!filter.timeframeType) {
			const timeframeString = [filter.from, filter.until].filter(v => !!v).map(v => formatter.format(convertInstantString(v!) as PlainDateTime)).join(' - ')
			switch (filter.timeframeType) {
				case 'All': return `${t('common.all')}: ${timeframeString}`;
				case 'Available': return `${t('common.available_in_timeframe')}: ${timeframeString}`;
				case 'Rented': return `${t('common.rented_in_timeframe')}: ${timeframeString}`;
			}
		}
		return undefined;
	}, [filter, language]);
	const { result: filterCategory } = useAsync(async () =>
		!!filter.categoryUuid ? categoryApi.get(centerId, filter.categoryUuid) : undefined,
		[filter.categoryUuid]);
	const { result: filterModel } = useAsync(async () =>
		!!filter.modelUuid ? modelApi.get(centerId, filter.modelUuid) : undefined,
		[filter.modelUuid]);

	function handleSearch(needle: string) {
		setFilter({
			...filter,
			needle
		})
	}

	function handleFilter(f: Omit<ItemListFilter, 'needle'>) {
		setFilter({
			needle: filter.needle,
			...f
		});
		clearFilterDialog();
	}

	function handleRemoveFilterChip(key: keyof ItemListFilter) {
		let current = { ...filter };
		delete current[key];
		setFilter(current)
	}

	function handleRemoveTimeframeFilter() {
		let current = { ...filter };
		delete current.timeframeType;
		delete current.from;
		delete current.until;
		setFilter(current);
	}

	function handleClearFilter() {
		setFilter({});
	}

	const { execute: regenerateItemCodes } = useAsyncCallback(() => itemApi.regenerateAllItemCodes(centerId));
	async function handleRegenerateItemCodes() {
		await regenerateItemCodes();
		await reload();
		clearAccessCodeDialog();
	}

	return (
		<RentalAppContainer
			title={t("items.plural")}
		>
			<Grid
				container
				spacing={3}
				justifyContent="flex-end"
			>
				<Grid
					item
					flexGrow={1}
				>
					<Stack
						direction="column"
						gap={1}
					>
						<Breadcrumbs>
							<BreadcrumbItem>{t("items.plural")}</BreadcrumbItem>
						</Breadcrumbs>
						<Typography variant="h4">{t("common.list")}</Typography>
					</Stack>

				</Grid>
				<Grid item>
					{tenantUserStore.admin && (
						<PushButtonWithMenu
							label={t("items.import.title")}
							onClick={() => navigate("/items/import")}
							sx={{ minWidth: 250 }}
						>
							<MenuItem onClick={setAccessCodeDialog}>
								{t("items.generateCodes.title")}
							</MenuItem>
						</PushButtonWithMenu>
					)}
				</Grid>
			</Grid>

			<Box my={3} />
			<Filter
				label="Filter"
				fullWidth
				enableSearch
				onSearch={handleSearch}
				onClear={handleClearFilter}
				labels={filterLabels()}
				active={[
					filter.needle && <Chip label={`${t('filter.search')}: ${filter.needle}`} onDelete={() => handleRemoveFilterChip('needle')} />,
					filter.enabled !== undefined && <Chip label={`${t('common.state')}: ${filter.enabled ? t('common.enabled') : t('common.disabled')}`} onDelete={() => handleRemoveFilterChip('enabled')} />,
					filter.modelUuid && <Chip label={`${t('models.singular')}: ${filterModel?.name ?? filter.modelUuid}`} onDelete={() => handleRemoveFilterChip('modelUuid')} />,
					filter.categoryUuid && <Chip label={`${t('categories.singular')}: ${filterCategory?.name ?? filter.categoryUuid}`} onDelete={() => handleRemoveFilterChip('categoryUuid')} />,
					hasTimeframeFilter !== undefined && <Chip label={hasTimeframeFilter} onDelete={() => handleRemoveTimeframeFilter()} />,
				]}
				onFilterClick={setFilterDialog}
			/>
			<DataTable
				header={
					<DataTableHeader>

					</DataTableHeader>
				}
				toolbar={<DataTableColumnSettings
					columns={available}
					selected={columns}
					onChange={setColumns}
					labels={columnSettingsLabels()}
				/>}
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				pageSizes={[25, 50, 100]}
				{...storage}
			/>
			{loading && <LinearProgress />}

			<ItemListFilterForm
				filter={filter}
				open={filterDialog}
				onClose={clearFilterDialog}
				onSubmit={handleFilter}
			/>

			<ConfirmDialog
				open={accessCodeDialog}
				title={t('items.generateCodes.title')}
				cancel={t('actions.cancel')}
				confirmTitle={t('actions.confirm')}
				onClose={clearAccessCodeDialog}
				onConfirm={handleRegenerateItemCodes}
			>
				<Typography>{t("items.generateCodes.message")}</Typography>
			</ConfirmDialog>

			{tenantUserStore.staff && <AddFab onClick={() => navigate("/items/create")} />}
		</RentalAppContainer>
	)
}

function ItemName({ row }: DataTableCellProps<V2Item>) {
	return (
		<Link component={RouterLink} to={`/items/${row.uuid}`}>
			{row.name || '-'}
		</Link>
	)
}

function ItemForeignId({ row }: DataTableCellProps<V2Item>) {
	return (
		<Link component={RouterLink} to={`/items/${row.uuid}`}>
			{row.foreignId || '-'}
		</Link>
	)
}

function ItemState({ row }: DataTableCellProps<V2Item>) {
	return (
		<ItemStateDisplay item={row} />
	)
}

function ItemCategory({ row }: DataTableCellProps<V2Item>) {
	if (!!row.categoryUuid) {
		return (
			<Link component={RouterLink} to={`/categories/${row.categoryUuid}`}>
				{row.categoryName || '-'}
			</Link>
		)
	}
	return <span>-</span>
}

function ItemModel({ row }: DataTableCellProps<V2Item>) {
	if (!!row.modelUuid) {
		return (
			<Link component={RouterLink} to={`/models/${row.modelUuid}`}>
				{row.modelName || '-'}
			</Link>
		)
	}
	return <span>-</span>
}

function ItemStorage({ cell: { row }, cubes }: { cell: DataTableCellProps<V2Item>, cubes: Cube[] }) {

	const getCubeName = useCallback((cubeId: string) => {
		return cubes.find(c => c.cubeId === cubeId)?.description ?? cubeId;
	}, [cubes]);

	if (!!row.stored) {
		return (
			<Stack
				direction="row"
				gap={1}
			>
				<Link component={RouterLink} to={`/cubes/${row.stored.cubeId}`}>
					{getCubeName(row.stored.cubeId)}
				</Link>
				/
				<Link component={RouterLink} to={`/cubes/${row.stored.cubeId}/boxes/${row.stored.boxNumber}`}>
					{row.stored.boxNumber || '-'}
				</Link>
			</Stack>
		)
	}
	return <span>-</span>
}

interface ItemListFilterFormProps {
	filter: ItemListFilter;
	open: boolean;
	onClose: () => void;
	onSubmit: (filter: Omit<ItemListFilter, 'needle'>) => void;
}

function ItemListFilterForm({ filter, open, onClose, onSubmit }: ItemListFilterFormProps) {
	const { t } = useLocalization();

	const [state, setState] = useState(filter.enabled ? '1' : (filter.enabled === undefined ? '' : '0'));
	const [modelUuid, setModelUuid] = useState(filter.modelUuid ?? '');
	const [categoryUuid, setCategoryUuid] = useState(filter.categoryUuid ?? '');
	const [timeframeType, setTimeframeType] = useState<ItemFilterTimeframeType>('All');
	const [from, setFrom] = useState<PlainDateTime | null>(!!filter.from ? convertInstantString(filter.from) : null);
	const [until, setUntil] = useState<PlainDateTime | null>(!!filter.until ? convertInstantString(filter.until) : null);

	useEffect(() => {
		const pState = filter.enabled ? '1' : (filter.enabled === undefined ? '' : '0');
		if (pState !== state) setState(pState);

		if (filter.modelUuid !== modelUuid) setModelUuid(filter.modelUuid ?? '');
		if (filter.categoryUuid !== categoryUuid) setCategoryUuid(filter.categoryUuid ?? '');
		if (filter.timeframeType !== timeframeType) setTimeframeType(filter.timeframeType ?? 'All');

		const pFrom = !!filter.from ? convertInstantString(filter.from) : null;
		if (pFrom !== from) setFrom(pFrom);

		const pUntil = !!filter.until ? convertInstantString(filter.until) : null;
		if (pFrom !== until) setUntil(pUntil);
	}, [filter]);

	async function handleFilterSubmit() {
		onSubmit({
			enabled: state === '1' ? true : (state === '0' ? false : undefined),
			modelUuid,
			categoryUuid,
			timeframeType,
			from: convertPlainDateTime(from),
			until: convertPlainDateTime(until),
		});
	}

	return (
		<Dialog open={open}>
			<DialogTitle>{t('actions.filter')}</DialogTitle>
			<DialogContent
				sx={{
					p: 0
				}}
			>
				<EditForm
					labels={formLabels()}
					loading={false}
					onSave={handleFilterSubmit}
					onCancel={onClose}
				>
					<Grid container spacing={3} p={2} pt={0.5}>
						<Grid item xs={12}>
							<Selector
								label={t('common.state')}
								value={state}
								onChange={setState}
								options={[
									{ label: t('common.all'), value: '' },
									{ label: t('common.enabled'), value: '1' },
									{ label: t('common.disabled'), value: '0' }
								]}
							/>
						</Grid>
						<Grid item xs={12}>
							<ModelSelection value={modelUuid} onChange={setModelUuid} />
						</Grid>
						<Grid item xs={12}>
							<CategorySelection value={categoryUuid} onChange={setCategoryUuid} />
						</Grid>
						<Grid item xs={12}>
							<RadioGroup
								row
								value={timeframeType}
								onChange={v => setTimeframeType(v as ItemFilterTimeframeType)}
								options={['All', 'Available', 'Rented']}
								renderLabel={o => {
									switch (o) {
										case 'All': return <span>{t('common.all')}</span>;
										case 'Available': return <span>{t('common.available_in_timeframe')}</span>;
										case 'Rented': return <span>{t('common.rented_in_timeframe')}</span>;
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<PlainDateTimePicker
								label={t("common.from")}
								fullWidth
								value={from}
								onChange={date => {
									setFrom(date);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<PlainDateTimePicker
								label={t("common.until")}
								fullWidth
								value={until}
								onChange={date => {
									setUntil(date);
								}}
							/>
						</Grid>
					</Grid>
				</EditForm>
			</DialogContent>

		</Dialog>
	)
}
